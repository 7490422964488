import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  height: ${props => (props.hasSplashRan ? "118px" : "352px")};
  transition: height 500ms ease-in-out;

  position: fixed;

  top: 30px;
  right: 30px;

  z-index: 1000;

  mix-blend-mode: ${props => (props.location === "/" ? "difference" : "none")};

  & path {
    fill: ${props => (props.location === "/" ? "#fff" : "#000")};
  }

  @media (max-width: 650px) {
    height: ${props => (props.hasSplashRan ? "118px" : "152px")};
  }

  @media (max-width: 500px) {
    height: ${props => (props.hasSplashRan ? "78px" : "152px")};
    top: 15px;
    right: 15px;
  }
`;

const Eakin = ({ splash, location }) => (
  <Icon viewBox="0 0 63 352" hasSplashRan={splash} location={location}>
    <path d="M60.9665 237.995L59.9433 247.207H3.0156L1.99248 237.995H0.118591V264.039H1.99248L3.0156 254.815H59.9433L60.9665 264.039H62.8404V237.995H60.9665Z" />
    <path d="M60.9665 0L59.9433 9.55771V9.56849H3.0156V9.55771L2.04632 0.269383H0.172426V58.3699L0.118591 58.4777H0.172426H2.04632H16.9837V56.6028L2.0571 54.1137L2.04632 54.1352V17.1651H30.7148V42.1315H19.256V44.0064H44.3168V42.1315H32.5887V17.1651H60.9665V50.9673L46.0292 53.1655V55.0404H62.8404V0H60.9665Z" />
    <path d="M25.6747 94.2087L55.1616 106.665L25.6747 118.723V94.2087ZM0.118591 72.0977V95.006H1.99248L2.97254 84.6078L20.4083 91.9889V120.888L2.9941 128L2.01404 119.164H0.140151V144.583H2.01404L2.9941 135.607L62.7972 111.104V103.562L58.0371 105.501L2.97254 82.1295L1.99248 72.1192L0.118591 72.0977Z" />
    <path d="M0 282.454V305.987H1.86311L3.01546 295.438H49.5829L0.118449 337.946V338.744V340.63H1.71232H59.9432L61.0957 351.168H62.9695V327.634H61.0957L59.9432 338.173H9.62792L62.6787 292.496V284.188L52.4369 292.981L3.01546 292.97L1.86311 282.454H0Z" />
    <path d="M60.9665 157.137L59.9433 166.231H3.11249H3.0156L1.99248 157.137H0.118591V183.18H1.99248L3.0156 173.838H31.0703V179.722L0.21548 206.94L0.118591 207.037V222.888H1.99248L3.0156 212.263L34.7211 184.204L59.9433 210.722L60.9665 222.166H62.8404V197.372H60.9665L59.9433 207.522L33.1703 179.7V173.838H59.9433L60.9665 183.18H62.8404V157.137H60.9665Z" />
  </Icon>
);

export default Eakin;
