import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Patrick from "../icons/Patrick";
import Eakin from "../icons/Eakin";
import Young from "../icons/Young";

const SplashWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  z-index: 200;
  background-color: #fff;

  opacity: ${props => (props.splash ? 0 : 1)};
  display: ${props => (props.hideBg ? "none" : "block")};

  transition: opacity 500ms ease-in-out;
`;

const Logo = ({ location }) => {
  const [hide, hideSplash] = useState(false);
  const [hideBg, triggerHideBg] = useState(false);

  useEffect(() => {
    if (hide !== true) {
      const splashTimer = setTimeout(() => {
        hideSplash(true);
        const hideBackground = setTimeout(() => {
          triggerHideBg(true);
        }, 550);
        return () => clearTimeout(hideBackground);
      }, 1000);

      return () => clearTimeout(splashTimer);
    }
  }, []);

  return (
    <React.Fragment>
      <SplashWrapper
        splash={hide}
        hideBg={hideBg}
        onClick={() => {
          hideSplash(true);
          triggerHideBg(true);
        }}
      />
      <Patrick splash={hide} location={location.pathname} />
      <Eakin splash={hide} location={location.pathname} />
      <Young splash={hide} location={location.pathname} />
    </React.Fragment>
  );
};

export default Logo;
