import React, { useState, useEffect, useMemo } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Location } from "@reach/router";
import { Normalize } from "styled-normalize";

import GlobalStyles from "../styles/globalStyles";
import Container from "../containers/container";
import App from "./app";
import Logo from "./logo";
import Navigation from "../navigation/navigation";
import SEO from "./seo";

function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export const Layout = ({ children }) => {
  const [isTouch, setisTouch] = useState(false);
  const windowSizeOnResize = useWindowSize();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Get window dimensions on load
    if (typeof window !== `undefined`) {
      if ("ontouchstart" in document.documentElement) {
        setisTouch(true);
      } else {
        setisTouch(false);
      }
    }
  });

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Location>
            {({ location }) => {
              return (
                <Container location={location.pathname}>
                  <SEO location={location} />
                  <Normalize />
                  <GlobalStyles />
                  <Logo location={location} />

                  {/* Load mobile navigation rules */}
                  {(isTouch === true || windowSizeOnResize.width <= 768) &&
                    (location.pathname === "/about" ||
                      location.pathname === "/about/" ||
                      location.pathname === "/about/#contact" ||
                      location.pathname === "/") && (
                      <Navigation
                        links={data.site.siteMetadata.menuLinks}
                        location={location.pathname}
                      />
                    )}

                  {/* Load desktop navigation rules */}
                  {isTouch === false &&
                    windowSizeOnResize.width > 768 &&
                    location.pathname !== "/about" &&
                    location.pathname !== "/about/" &&
                    location.pathname !== "/about/#contact" && (
                      <Navigation
                        links={data.site.siteMetadata.menuLinks}
                        location={location.pathname}
                      />
                    )}

                  <App location={location} data={data} children={children} />
                </Container>
              );
            }}
          </Location>
        );
      }}
    />
  );
};

export default Layout;
