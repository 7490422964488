import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a8873");


@font-face {
  font-family: 'UniversLTPro-45Light';
  src: url('/fonts/3A8873_0_0.eot');
  src: url('/fonts/3A8873_0_0.eot?#iefix') format('embedded-opentype'),url('/fonts/3A8873_0_0.woff2') format('woff2'),url('/fonts/3A8873_0_0.woff') format('woff'),url('/fonts/3A8873_0_0.ttf') format('truetype');}

  body {
    color: #1D1D1B;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;

    font-family: 'UniversLTPro-45Light', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

    overflow-x: hidden;

    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.05em;
  }


    strong {
      font-weight: normal;
    }

    & a,
    & a:visited {
      text-decoration: none;
      color: inherit;
    }

    & a:focus{
      outline: none;
    }

    & a.active{
      color: #fa3c44;
    }


    h1, h2, h3, h4, h5, h6{
      margin: 0;
      font-weight: normal;
    }

    h1 {
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.05em;
    }

    button {
      color: #1D1D1B;
      background-color: transparent;
      border:0;
      border-radius:0;
      -webkit-appearance: none;

      &:hover {
      color: #1D1D1B;
      }

      &:focus {
        outline: 0;
      }

    }


    img {
      width: 100%;
      display: block;
    }


`;

export default GlobalStyle;
