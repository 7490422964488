import React from "react";
import styled from "styled-components";

const ContentContainer = styled.div`
  position: relative;
  background: #fff;
  padding: ${props =>
    props.location === "/about" ||
    props.location === "/about/" ||
    props.location === "/about/#contact"
      ? "30px"
      : "30px 0"};

  @media (max-width: 500px) {
    padding: ${props =>
      props.location === "/about" ||
      props.location === "/about/" ||
      props.location === "/about/#contact"
        ? "25px"
        : "25px 0"};
  }
`;

const Container = ({ location, children }) => {
  return <ContentContainer location={location}>{children}</ContentContainer>;
};

export default Container;
