import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Icon = styled.svg`
  height: ${props => (props.hasSplashRan ? "21px" : "63px")};
  transition: height 500ms ease-in-out;

  position: fixed;

  top: 30px;
  left: 30px;

  z-index: 1000;

  mix-blend-mode: ${props => (props.location === "/" ? "difference" : "none")};

  & path {
    fill: ${props => (props.location === "/" ? "#fff" : "#000")};
  }

  @media (max-width: 650px) {
    height: ${props => (props.hasSplashRan ? "21px" : "27px")};
  }

  @media (max-width: 500px) {
    height: ${props => (props.hasSplashRan ? "14px" : "27px")};
    top: 15px;
    left: 15px;
  }
`;

const Patrick = ({ splash, location }) => (
  <Link to={"/"}>
    <Icon viewBox="0 0 120.64 24.57" hasSplashRan={splash} location={location}>
      <path d="M53.02.22v.73l3.54.39v21.9l-3.54.39v.72h10.01v-.72l-3.55-.39V1.34l3.55-.39V.22zM10.74 12.94c-1.6 0-2.91-.1-4.12-.27V.95h3.04c6.26 0 8.59 1.56 8.59 5.76-.01 4.31-2.32 6.23-7.51 6.23M9.66.22H0v.72l3.7.39v21.9l-3.7.4v.72h10.41v-.72l-3.79-.39V13.4c1.14.16 2.5.26 4.08.26 6.46 0 10.32-2.6 10.32-6.95C21.03 2.34 17.31.22 9.66.22M34.38 3.17l4.67 11.42h-9.49l4.82-11.42zM36.1.23H33.17l.75 1.83-8.99 21.19-3.85.38v.72h8.81v-.72l-4-.38 2.8-6.62h11.18l2.7 6.61v.01l-3.4.38v.72h9.77v-.72l-3.45-.38L36.1.23zM83.42 12.68v.8h4.16l-.01.33c-.04 5.65-2.77 8.89-7.29 8.89-5.01 0-9.07-4.53-9.07-11.55C71.21 4.82 74.7.73 80.1.73c4.1 0 6.97 2.36 7.78 6.21h3.02C89.8 2.18 85.17 0 80.7 0c-7.35 0-12.1 5.09-12.1 12.96 0 6.94 4.81 11.8 10.74 11.61 6.28-.2 9.07-4.12 9.07-10.76v-.32h1.5v10.55h2.02V12.68h-8.51zM98.16.94l3.67.4v21.9l-3.57.37v.72h22.33l.05.02v-6.49h-.72l-.96 5.75h.01-14.22V12.58h9.6v4.41h.73V7.35h-.73v4.51h-9.6V.94h13l.84 5.75h.73V.22H98.16z" />

      {/* <path d="M27.9362 33.6406C24.3343 33.6633 20.7353 33.4292 17.1667 32.9402V2.43522H25.0607C41.3227 2.43522 47.3752 6.50829 47.3752 17.4345C47.3752 28.6408 41.3658 33.5975 27.8823 33.5975L27.9362 33.6406ZM25.0607 0.517212H0V2.39212L9.6172 3.46965V60.4603L0 61.5379V63.4128H27.0639V61.5379L17.2313 60.4603V34.8258C20.7468 35.3039 24.2915 35.5343 27.8393 35.5155C44.6398 35.5155 54.677 28.7486 54.677 17.4345C54.677 6.12038 44.9844 0.560314 25.1253 0.560314" />
      <path d="M257.984 16.1414C257.984 26.3241 254 29.7614 240.527 29.7614H228.12V2.4352H236.93C253.084 2.4352 257.984 5.89408 257.984 16.1414ZM247.473 31.3669C250.803 31.0453 254.043 30.1023 257.026 28.5869C257.36 28.4253 258.491 27.7788 258.491 27.7788C260.562 26.6243 262.28 24.9291 263.464 22.8739C264.647 20.8188 265.251 18.4807 265.211 16.1091C265.211 5.68934 255.96 0.614166 236.93 0.614166H213.377V2.48907L221.228 3.5666V60.4603L213.377 61.5378V63.402H237.07V61.5378L228.164 60.4603V31.6255H235.185L266.18 63.3158H274.279L243.047 31.5824C244.049 31.5824 246.903 31.3993 247.506 31.3131" />
      <path d="M362.46 58.1867C347.189 58.1867 337.313 46.0753 337.313 27.3262C337.313 12.1222 346.305 1.90723 359.67 1.90723C370.612 1.90723 377.397 7.76901 378.829 18.0702H386.422C384.171 5.68937 372.249 0.0107727 360.414 0.0107727C342.956 0.0107727 330.259 13.4799 330.259 32.035C330.259 51.0858 342.181 63.8761 359.918 63.8761C374.392 63.8761 385.496 53.8551 386.465 39.9657H384.591C382.976 51.0535 374.306 58.1975 362.427 58.1975" />
      <path d="M442.607 0.560303V2.44598L452.762 3.46964L424.945 30.2463H419.086V3.46964L428.434 2.44598V0.560303H402.393V2.44598L411.493 3.46964V60.331V60.428L402.393 61.4516V63.3266H428.434V61.4516L419.086 60.428V32.3583H424.966L452.181 63.2188L452.267 63.3266H468.109V61.4516L457.49 60.428L429.457 28.7054L455.961 3.46964L467.387 2.44598V0.560303H442.607Z" />
      <path d="M287.945 0.54953V2.43521L297.164 3.46964V60.428L287.945 61.4409V63.3158H313.975V61.4409L304.757 60.428V3.46964L313.975 2.43521V0.54953H287.945Z" />
      <path d="M136.289 0.560303V17.3914H138.173L140.553 2.44598H161.629V60.428L152.109 61.4516V63.3266H178.753V61.4516L169.233 60.428V2.44598H190.136L192.592 17.3914H194.466V0.560303H136.289Z" />
      <path d="M92.952 8.2L105.089 37.9076H80.4162L92.952 8.2ZM97.4321 0.560303H89.8288L91.7781 5.32299L68.4082 60.4603L58.4033 61.4517V63.3158H81.2993V61.4517L70.9067 60.4603L78.1977 43.2198H107.275L114.297 60.4603L105.466 61.4517V63.3158H130.85V61.4517L121.89 60.4603L97.4321 0.560303Z" /> */}
    </Icon>
  </Link>
);

export default Patrick;
