import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const NavBar = styled.nav`
  position: fixed;
  right: 37px;
  bottom: 149px;

  transform: translateZ(0) rotate(90deg);
  transform-origin: right;
  backface-visibility: hidden;
  outline: 1px solid transparent;

  z-index: 100;

  & ul,
  & li {
    margin: 0;
    list-style: none;
  }

  & li {
    padding-left: 20px;
  }

  & a {
    position: relative;
  }

  a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: #000;
    visibility: hidden;
  }

  a:hover:before,
  & a.current:before {
    visibility: visible;
  }

  @media (hover: none) {
    a:hover:before {
      display: none;
    }
  }

  @media (max-width: 767px) {
    bottom: 19px;
  }

  @media (max-width: 500px) {
    right: 17px;
  }
`;

const Navigation = ({ links, location }) => (
  <NavBar>
    <ul style={{ display: "flex", padding: 0, width: "210px" }}>
      {links.map(link => (
        <li key={link.name}>
          <Link
            to={link.link}
            activeClassName="current"
            className={
              location.includes("projects") && link.name === "Projects"
                ? "current"
                : ""
            }
            state={{ contact: false }}
          >
            {link.name}
          </Link>
        </li>
      ))}
      <li>
        <Link to={`/about/#contact`} state={{ contact: true }}>
          Contact
        </Link>
      </li>
    </ul>
  </NavBar>
);

export default Navigation;
