import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  height: ${props => (props.hasSplashRan ? "21px" : "63px")};
  transition: height 500ms ease-in-out;

  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  right: 0;
  bottom: 30px;

  z-index: 1000;

  mix-blend-mode: ${props => (props.location === "/" ? "difference" : "none")};

  & path {
    fill: ${props => (props.location === "/" ? "#fff" : "#000")};
  }

  @media (max-width: 650px) {
    height: ${props => (props.hasSplashRan ? "21px" : "27px")};
  }

  @media (max-width: 500px) {
    height: ${props => (props.hasSplashRan ? "14px" : "27px")};
    bottom: 15px;
  }
`;

const Young = ({ splash, location }) => (
  <Icon viewBox="0 0 368 65" hasSplashRan={splash} location={location}>
    <path d="M193.69 2.9848L204.212 4.00841V40.4291C204.212 54.7818 199.657 62.4215 185.721 62.637C174.284 62.1629 168.78 56.6674 168.78 43.1552V3.88989L177.062 2.9848V1.09912H151.043V2.9848L161.037 4.17006L161.199 43.1552C161.088 47.1944 162.175 51.1761 164.322 54.5986C167.553 61.1392 174.079 64.7274 185.053 64.7274H185.29H186.076V64.652C201.046 64.2533 206.538 55.73 206.538 40.4291V4.00841L217.179 2.9848V1.09912H193.69V2.9848Z" />
    <path d="M345.39 33.5112V35.6016H356.16V36.4637C356.074 51.1504 348.976 59.5875 337.205 59.5875C324.174 59.5875 313.62 47.7347 313.62 29.5351C313.62 13.0812 322.699 2.43521 336.742 2.43521C347.393 2.43521 354.867 8.57715 356.967 18.5982H364.818C361.986 6.21735 349.924 0.527954 338.325 0.527954C319.22 0.527954 306.878 13.7601 306.878 34.2332C306.878 52.3035 319.382 64.9321 334.793 64.4041C351.109 63.8869 358.367 53.6288 358.367 36.3883V35.5478H362.288V63.0033H367.532V33.5112H345.39Z" />
    <path d="M108.956 62.3352C94.1905 62.3352 83.8841 48.4674 83.8841 28.6192C83.8841 12.9627 93.3074 2.04727 106.802 2.04727C121.513 2.04727 131.787 15.7966 131.787 35.4507C131.787 51.0103 122.181 62.3029 108.956 62.3029V62.3352ZM108.299 0C92.2304 0.0323259 76.0761 11.0878 76.0761 32.2397C76.0761 51.1935 88.9996 64.4364 107.588 64.4364C123.462 64.4364 139.616 53.09 139.616 31.4424C139.616 12.9735 126.693 0.0754351 108.309 0.0754351" />
    <path d="M45.9213 2.98478L56.1093 4.00838L37.3918 32.0242L17.3174 4.00838L26.0623 2.98478V1.0991H0V2.98478L9.63875 4.00838L30.9301 33.6405V60.9668L22.0883 62.0443V63.9192H48.743V62.0443L38.5334 60.9668V34.1254L58.7263 3.95452L69.4959 2.93085V1.04517H45.9213V2.98478Z" />
    <path d="M273.288 0.991302V2.86626L283.81 4.00845L283.821 54.3615L238.168 1.28226H229.865L238.653 11.4542L238.632 60.9668L228.131 62.1305V63.9947H251.652V62.1305L241.098 60.9668L241.109 14.2989L283.594 63.4667H286.276L286.265 4.00845L296.809 2.86626V0.991302H273.288Z" />
  </Icon>
);

export default Young;
// {/* <svg width="368" height="65" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
