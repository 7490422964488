import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const SEO = ({ location, data }) => {
  if (location.pathname === "/" || location.pathname === "/about") {
    return (
      <Helmet
        title={"Patrick Eakin Young"}
        meta={[
          {
            name: "google",
            content: `notranslate`,
          },
          {
            name: "description",
            content: `${data.prismicAbout.data.text.text}`,
          },
          {
            name: "og:image",
            // content: `${data.prismicArtist.data.body[0].primary.image.url}`,
          },
          {
            name: "og:image:secure_url",
            // content: `${data.prismicArtist.data.body[0].primary.image.url}`,
          },
          {
            name: "og:description",
            content: `${data.prismicAbout.data.text.text}`,
          },
          {
            name: "og:image:width",
            content: `1200`,
          },
          {
            name: "og:image:height",
            content: `630`,
          },
          {
            name: "og:locale",
            content: `en`,
          },
          {
            name: "twitter:title",
            // content: `${data.prismicArtist.data.name.text} – Patrick Eakin Young`,
          },
          {
            name: "twitter:description",
            content: `${data.prismicAbout.data.text.text}`,
          },
          {
            name: "twitter:card",
            content: `summary_large_image`,
          },
          {
            name: "twitter:image",
            // content: `${data.prismicArtist.data.body[0].primary.image.url}`,
          },
        ]}
      />
    );
  } else {
    return null;
  }
};
export default props => (
  <StaticQuery
    query={graphql`
      {
        prismicAbout {
          data {
            text {
              text
            }
          }
        }
      }
    `}
    render={data => <SEO data={data} {...props} />}
  />
);
